/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.page-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-panel-bkg-color {
  background-color: #f4f3ef;
}

.popover-calendar > .popover {
  width: 350px;
  min-width: 350px;
}
