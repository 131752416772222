.rbt.open .form-control {
  border-radius: 4px;
  border: 1px solid #dddddd;
}

.rbt.open .form-control.focus {
  border-radius: 4px;
  border: 1px solid #9a9a9a;
}

.rbt.open .form-control.is-valid {
  border-radius: 4px;
  border: 1px solid #28a745;
}

.rbt.open .form-control.is-valid.focus {
  border-radius: 4px;
  border: 1px solid #28a745;
}

.rbt.open .form-control.is-invalid {
  border-radius: 4px;
  border: 1px solid #dc3545;
}

.rbt.open .form-control.is-invalid.focus {
  border-radius: 4px;
  border: 1px solid #dc3545;
}

.rbt-menu.dropdown-menu.show {
  background-color: #ffffff;
  border: 1px solid #9a9a9a;
  border-style: double;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0px;
}

.rbt-menu.dropdown-menu.show .dropdown-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rbt-menu.dropdown-menu.show .dropdown-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-cron-generator .container .panel-row {
  height: 184px;
}

.nav-link {
  cursor: pointer;
}